import { Col, Grid, Image, Row, Typography } from 'antd'
import { SectionStyle } from './FPContent'

const { useBreakpoint } = Grid
const { Link, Title, Paragraph } = Typography
const cogs = require('./cogs.jpg')

export const HiringSection = ({
  sectionStyle: { rowStyle, textBoxStyle, imageBoxStyle },
}: SectionStyle) => {
  const breakpoints = useBreakpoint()
  const textMargin = breakpoints.lg
    ? { marginLeft: '-50px', marginRight: '-50px' }
    : { marginTop: '-20px' }
  return (
    <Row style={rowStyle}>
      <Col
        xxl={{ span: 6, offset: 5 }}
        xl={{ span: 8, offset: 4 }}
        lg={{ span: 11, offset: 1 }}
        xs={{ span: 24 }}
        style={{ ...imageBoxStyle }}
      >
        <Image
          preview={false}
          src={cogs}
          style={{ borderRadius: '10px' }}
          alt="The internals of a technical device with cogs showing"
        />
      </Col>
      <Col
        xxl={8}
        xl={10}
        lg={11}
        xs={{ span: 22, offset: 1 }}
        style={{ ...textBoxStyle, ...textMargin }}
        span={8}
      >
        <Title>Level up your skills!</Title>
        <Paragraph>
          We are constantly interested in skilled professionals from all fields
          of software development.
        </Paragraph>
        <Paragraph>
          If you are a developer, tester, UX/UI designer or anything in between,
          and you are looking for a new challenge, then send us your resume
          and/or github repo!
        </Paragraph>
        <Paragraph>
          <Link
            href="mailto:jobs@foxpaint.io"
            style={{ fontWeight: 600, fontSize: '16px' }}
          >
            jobs@foxpaint.io
          </Link>
        </Paragraph>
      </Col>
    </Row>
  )
}
