import { Col, Layout, Row, Space, Typography } from 'antd'
import { gray } from '@ant-design/colors'

const { Footer } = Layout
const { Text } = Typography
const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  backgroundColor: gray[6],
  marginTop: '64px',
}

export const FPFooter = () => (
  <Footer style={footerStyle}>
    <Row justify="space-around">
      <Col span={24}>
        <Space direction="vertical">
          <Text style={{ color: 'white' }}>Foxpaint AB</Text>
          <Text style={{ color: 'white' }}>Box 3203</Text>
          <Text style={{ color: 'white' }}>350 53 Växjö, Sweden</Text>
          <Text style={{ color: 'white' }}>VAT: SE559407491501, Org nr: 559407-4915</Text>
          <Text style={{ color: 'white' }}>D-U-N-S nr: 315317816</Text>
          <Text style={{ color: 'white' }}>Copyright Foxpaint AB, 2023</Text>
        </Space>
      </Col>
    </Row>
  </Footer>
)
