import { Col, Grid, Image, Row, Typography } from 'antd'
import { SectionStyle } from './FPContent'

const { Link, Paragraph, Title } = Typography
const { useBreakpoint } = Grid
const programmer = require('./programmer.jpg')

export const ServicesSection = ({
  sectionStyle: { rowStyle, textBoxStyle, imageBoxStyle },
}: SectionStyle) => {
  const breakpoints = useBreakpoint()
  const imageMargin = breakpoints.lg
    ? { marginLeft: '-50px', marginRight: '-50px' }
    : {}
  const textMargin = breakpoints.lg
    ? { marginBottom: '50px', marginTop: '50px' }
    : { marginTop: '-20px' }
  return (
    <Row style={rowStyle} itemType="flex">
      <Col
        xxl={{ span: 8, offset: 4 }}
        xl={{ span: 10, offset: 3 }}
        lg={{ span: 11, offset: 1, order: 0 }}
        xs={{ span: 22, offset: 1, order: 1 }}
        style={{ ...textBoxStyle, ...textMargin }}
      >
        <Title>Level up your dev team!</Title>
        <Paragraph>
          Looking to strengthen your dev team with expertise in software
          development, testing or UX/UI? Look no further!
        </Paragraph>
        <Paragraph>
          We are a team of highly skilled software development professionals. We
          can help your organisation meet every challenge you may face.
        </Paragraph>
        <Paragraph>
          <Link
            href="mailto:contact@foxpaint.io"
            style={{ fontWeight: 600, fontSize: '16px' }}
          >
            contact@foxpaint.io
          </Link>
        </Paragraph>
      </Col>
      <Col
        xxl={8}
        xl={10}
        lg={{ span: 11, order: 1 }}
        sm={{ span: 24, order: 0 }}
        xs={{ span: 24, order: 0 }}
        style={{
          ...imageBoxStyle,
          ...imageMargin,
        }}
      >
        <Image
          preview={false}
          src={programmer}
          style={{ borderRadius: '10px' }}
          alt="A senior developer looking at the camera with a fox on a monitor behind him"
        />
      </Col>
    </Row>
  )
}
