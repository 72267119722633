import './App.css'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { ConfigProvider, Layout, Space, theme } from 'antd'
import { FPHeader } from './FPHeader/FPHeader'
import { FPContent } from './FPContent/FPContent'
import { FPFooter } from './FPFooter/FPFooter'
import type { FC } from 'react'
import { foxpaintTheme } from './ant-theme'

const firebaseConfig = {
  apiKey: 'AIzaSyCLLkfjUJtSm11AKh2_FlzR1kv2QYUMH10',
  authDomain: 'foxpaint-32434.firebaseapp.com',
  projectId: 'foxpaint-32434',
  storageBucket: 'foxpaint-32434.appspot.com',
  messagingSenderId: '488794607909',
  appId: '1:488794607909:web:7c6f7b5c375292f7f38acc',
  measurementId: 'G-VRK9Q196GN',
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

logEvent(analytics, 'site-load')

const App: FC = () => (
  <ConfigProvider
    theme={{ ...foxpaintTheme, algorithm: theme.defaultAlgorithm }}
  >
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <FPHeader />
        <FPContent />
        <FPFooter />
      </Layout>
    </Space>
  </ConfigProvider>
)

export default App
