import { Col, Grid, Image, Row, Typography } from 'antd'
import { SectionStyle } from './FPContent'

const threeProgrammers = require('./three-programmers.jpg')
const { useBreakpoint } = Grid
const { Paragraph, Title } = Typography

export const AboutSection = ({
  sectionStyle: { rowStyle, textBoxStyle, imageBoxStyle },
}: SectionStyle) => {
  const breakpoints = useBreakpoint()
  const imageMargin = breakpoints.lg
    ? { marginTop: '-30px' }
    : { marginTop: '-20px' }
  const textPadding = breakpoints.lg
    ? { paddingBottom: '30px' }
    : { paddingBottom: '20px' }
  return (
    <>
      <Row style={rowStyle}>
        <Col
          xxl={{ span: 12, offset: 6 }}
          xl={{ span: 14, offset: 5 }}
          lg={{ span: 18, offset: 3 }}
          xs={{ span: 24 }}
          style={{ ...textBoxStyle, ...textPadding }}
        >
          <Title>Who we are!</Title>
          <Paragraph>
            Foxpaint.io was started by experienced software developers in 2023.
          </Paragraph>
          <Paragraph>
            We have an extensive background in software development with 15
            years of experience in areas such as mobile gaming, online casino,
            HR systems, publishing and more. We have built multiple systems with
            high requirements on usability, reliability and performance.
          </Paragraph>
          <Paragraph>
            With Foxpaint.io we have the chance to continue doing the things we
            love doing, while helping out your organisation!
          </Paragraph>
        </Col>
      </Row>
      <Row>
        <Col
          xxl={{ span: 10, offset: 7 }}
          xl={{ span: 12, offset: 6 }}
          lg={{ span: 16, offset: 4 }}
          xs={{ span: 22, offset: 1, order: 0 }}
          style={{
            ...imageBoxStyle,
            zIndex: 5,
            ...imageMargin,
          }}
        >
          <Image
            preview={false}
            style={{ borderRadius: '10px' }}
            src={threeProgrammers}
            alt={
              'Three energetic and happy programmers sitting at their computers.'
            }
          />
        </Col>
      </Row>
    </>
  )
}
