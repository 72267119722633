import { Grid, Layout, Image } from 'antd'

const { Header } = Layout
const { useBreakpoint } = Grid
const logo = require('./foxpaint-logo.png')
const landscape = require('./landscape.jpg')

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: 'white',
  height: 600,
  paddingInline: 50,
  lineHeight: '71px',
  backgroundColor: 'black',
  backgroundImage: `url(${landscape})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
}

export const FPHeader = () => {
  const breakpoints = useBreakpoint()
  const logoStyle = breakpoints.lg
    ? { marginTop: '150px', height: '150px' }
    : { marginTop: '200px', height: '100px' }

  return (
    <Header style={headerStyle}>
      <Image
        alt="Foxpaint.io logo"
        preview={false}
        src={logo}
        style={logoStyle}
      />
    </Header>
  )
}
