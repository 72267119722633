import { Layout } from 'antd'
import { gray } from '@ant-design/colors'
import { AboutSection } from './AboutSection'
import { HiringSection } from './HiringSection'
import { ServicesSection } from './ServicesSection'

const { Content } = Layout
const contentStyle: React.CSSProperties = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: gray[8],
  backgroundColor: 'whitesmoke',
}

const rowStyle: React.CSSProperties = {
  marginTop: '64px',
  alignItems: 'center',
}

const textBoxStyle: React.CSSProperties = {
  textAlign: 'center',
  minHeight: 260,
  lineHeight: '120px',
  color: 'black',
  backgroundColor: 'white',
  zIndex: 1,
  boxShadow: '3px 3px 3px 2px rgba(0, 0, 0, 0.2)',
  borderRadius: '10px',
  paddingLeft: '20px',
  paddingRight: '20px',
}

const imageBoxStyle: React.CSSProperties = {
  textAlign: 'center',
  lineHeight: '120px',
  color: 'white',
  backgroundColor: gray[8],
  boxShadow: '3px 3px 3px 1px rgba(0, 0, 0, 0.2)',
  borderRadius: '10px',
}

export interface SectionStyle {
  sectionStyle: {
    rowStyle: React.CSSProperties
    textBoxStyle: React.CSSProperties
    imageBoxStyle: React.CSSProperties
  }
}

export const FPContent = () => (
  <Content style={contentStyle}>
    <ServicesSection sectionStyle={{ rowStyle, textBoxStyle, imageBoxStyle }} />
    <HiringSection sectionStyle={{ rowStyle, textBoxStyle, imageBoxStyle }} />
    <AboutSection sectionStyle={{ rowStyle, textBoxStyle, imageBoxStyle }} />
  </Content>
)
